import "../css/Work.css";
import "../css/App.css";

import CalloutCard from "./CalloutCard";
import ContentCard from "./ContentCard";
import Intro from "../images/Work/Intro.jpg";
import TermProject1 from "../images/Work/TermProject1.jpg";
import TermProject2 from "../images/Work/TermProject2.jpg";
import FAQ from "../images/Work/FAQ.jpg";

function Work() {
    return (
        <div id="work-wrapper">
            <div className="narrow">
                <h1>Work With Us</h1>
                <div className="short-underline"></div>
            </div>
            <div className="narrow introduction">
                <div className="row">
                    <div className="col-6 left">
                        <div className="intro-text">
                            <p>
                                Each term, CodeLab works with several clients to
                                develop clients for them. These projects can be
                                associated with any discipline in computer
                                science, including, but not limited to, web
                                development, data science, or mobile app
                                development. We do not charge for our services,
                                but we hope your organization can provide
                                support to our developers and designers.
                            </p>
                        </div>
                        <CalloutCard
                            title="💌️ Let’s Chat!"
                            description="If you’re interested in working with us, email us at codelabdavis@gmail.com or fill out the client interest form below."
                            colorScheme="light"
                        />
                    </div>
                    <div className="col-6 right">
                        <img
                            src={Intro}
                            className="intro-image"
                            alt="People collaborating"
                        ></img>
                    </div>
                </div>
            </div>

            <div className="narrow">
                <div className="form-background">
                    <div className="row two">
                        <div className="col-12 form">
                            <h2>Client Interest Form</h2>
                            <p>
                                Please fill out the form below to tell us more
                                about potential collaboration. We will get back
                                to you shortly!
                            </p>
                            <iframe
                                className="airtable-embed"
                                src="https://airtable.com/embed/shr3SUa2whYdg5Diq?backgroundColor=green"
                                frameborder="0"
                                onmousewheel=""
                                width="100%"
                                height="700"
                                style={{
                                    background: "transparent",
                                    border: "1px solid #ccc",
                                }}
                                title="Client Interest Form"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div className="narrow project-term">
                <div className="row">
                    <div className="col-6 left">
                        <img
                            src={TermProject1}
                            alt="Students at desks with laptops"
                        />
                        <h2 class="term-header">The Term</h2>
                        <p>
                            CodeLab has two terms each year, one during the Fall
                            quarter (mid-October to late November) and one
                            during the Winter and Spring quarters (late January
                            to late May). The Fall term runs for approximately 6
                            weeks and the Winter/Spring term runs for
                            approximately 16 weeks. Depending on the scale of
                            your project, you may want to work with us during
                            one term over the other.
                        </p>
                        <p>
                            Teams meet with their clients at least two times a
                            term. The term starts with an initial meeting to
                            discuss project requirements, and the completed
                            projects are presented at the Final Presentations.
                            For the Winter/Spring term, clients are updated on
                            progress at our Midterm Presentations.
                        </p>
                    </div>
                    <div className="col-6 right">
                        <h2>The Project</h2>
                        <p>
                            Projects can involve any field of software
                            engineering, including but not limited to web
                            development, mobile app development, and data
                            sceince. Members are recruited at the beginning of
                            each term to find students best fit for projects. We
                            also start the term with boot camps for developers
                            to learn the technical stack and for designers to
                            study the design process.
                        </p>
                        <p>
                            For projects involving interfaces, such as for web
                            and mobile, our designers apply UX research methods
                            to better understand the needs of your business and
                            the end user. After the initial creation of
                            wireframes, developers begin implementing the
                            screens while designers continue to test and iterate
                            on the designs. This process results in robust,
                            polished end products.
                        </p>
                        <p>
                            We hope projects can benefit your company or
                            organization. It is best that projects are somewhat
                            independent of the company’s operations, in order to
                            grant developers more flexibility in the timeline
                            and the work itself. Members in CodeLab are expected
                            to put in 8+ hours a week, but are still full time
                            students working on coursework amidst the project
                            term.
                        </p>
                        <img src={TermProject2} alt="Team" />
                    </div>
                </div>
            </div>

            <div className="past-work">
                <div className="row">
                    <CalloutCard
                        title="🚀 View Our Past Work"
                        description="See some of our past projects with clients we’ve worked with on our Projects page."
                        colorScheme="light"
                        buttonLink="/projects"
                        buttonTitle="View Projects"
                    />
                </div>
            </div>

            <div className="diagonal-box faq">
                <div className="content narrow faq-content">
                    <div className="row">
                        <div className="col-12">
                            <h2>Frequently Asked Questions</h2>
                        </div>
                        <div className="col-6">
                            <p className="bold question">What is CodeLab?</p>
                            <p>
                                CodeLab is an undergraduate organization at UC
                                Davis that works on software and design projects
                                for clients. Students work in interdisciplinary
                                teams over one or two academic terms to develop
                                their technical skills and get real world
                                experience in the tech industry.
                            </p>
                            <hr />
                            <p className="bold question">
                                What is the cost of your services?
                            </p>
                            <p>
                                Our student developers and designers work
                                pro-bono, but we hope your organization can
                                provide support to our developers and designers
                                throughout the project term. We additionally
                                welcome monetary donations to our organization
                                to help support our mission of jumpstarting
                                students' careers in the tech industry.
                            </p>
                        </div>
                        <div className="col-6">
                            <img
                                src={FAQ}
                                alt="Students drawing on whiteboard"
                            />
                        </div>
                        <div className="col-12">
                            <p className="red-blurb">
                                If you have any further questions, please
                                contact us at codelabdavis@gmail.com.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="narrow other-ways">
                <div className="row">
                    <div className="col-12">
                        <h2>Workshops, Office Visits, and More</h2>
                        <p>
                            In addition to working with up on a project, there
                            are other ways to collaborate with CodeLab! If you
                            or your organization is interested, please reach out
                            at codelabdavis@gmail.com.
                        </p>
                    </div>
                    <div className="col-md-6 content-card">
                        <ContentCard
                            colorScheme={"dark"}
                            title={"✏️ Workshops"}
                            description={
                                "We host computer science, design, and professional development workshops for our members to gain new skills."
                            }
                        />
                    </div>
                    <div className="col-md-6 content-card">
                        <ContentCard
                            title={"🎙️ Speakers"}
                            colorScheme={"dark"}
                            description={
                                "In addition to workshops, we host speakers events and panels."
                            }
                        />
                    </div>
                    <div className="col-md-6 content-card">
                        <ContentCard
                            title={"🏢️ Office Visits"}
                            colorScheme={"dark"}
                            description={
                                "To give members a peek into the industry, we tour offices of tech companies."
                            }
                        />
                    </div>
                    <div className="col-md-6 content-card">
                        <ContentCard
                            title={"💡️ Other"}
                            colorScheme={"dark"}
                            description={
                                "If you have other ideas on how to collaborate, feel free to reach out!"
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Work;
