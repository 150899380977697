import "@css/App.css";
import "@css/ContentCard.css";

function ContentCardWithBullets2(props) {
    return (
        // <div id="content-card-wrapper">
        <div>
            <div className="flex flex-wrap gap-y-4 rounded-lg border-2 border-solid border-neutral-400 bg-neutral-700 p-4 text-white">
                <h3 className="w-full text-inherit">{props.title}</h3>
                <div className="grid grid-cols-2 text-inherit">
                <div className="col-span-1 flex flex-wrap content-start items-start text-inherit">
                        <p className="mb-2 font-semibold text-inherit">
                            {props.subtitle1}
                        </p>
                        <ul className="flex flex-wrap items-start gap-y-2 text-inherit">
                            {props.content1.map((bullet) => {
                                return (
                                    <li className=" w-full text-inherit">
                                        {bullet}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="col-span-1 flex flex-wrap content-start items-start text-inherit">
                        <p className="mb-2 font-semibold text-inherit">
                            {props.subtitle2}
                        </p>
                        <ul className="flex flex-wrap items-start gap-y-2 text-inherit">
                            {props.content2.map((bullet) => {
                                return (
                                    <li className=" w-full text-inherit">
                                        {bullet}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ContentCardWithBullets2;
