import "../css/App.css";
import "../css/CalloutCard.css";

function CalloutCard(props) {
    return (
        <div className="callout-card-wrapper">
            <div className={props.colorScheme + " contents"}>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
                {props.buttonLink && props.buttonTitle && (
                    <a className="button" href={props.buttonLink}>
                    {props.buttonTitle}
                    </a>
                )}
            </div>
        </div>
    );
}

export default CalloutCard;
