import "../css/Footer.css";
import logo from "../images/Logo.svg";
import MailchimpSubscribe from "react-mailchimp-subscribe";

function Footer() {
    // const [email, setEmail] = useState(null);
    // function handleTextChange(event) {
    //     setEmail(event.target.value)
    //     setDisplay(false);
    // }
    // const [displaySubcribed, setDisplay] = useState(false);

    // function hitSubscribe() {
    //     setEmail("");
    //     setDisplay(true);
    // }

    const SimpleForm = () => (
        <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} />
    );

    return (
        <div id="footer-wrapper" className="wide">
            <div className="row">
                <div className="col-6 col-sm-2 general-category">
                    <p className="small-text bold">General</p>
                    <p className="small-text">
                        <a href="/">Home</a>
                    </p>
                    <p className="small-text">
                        <a href="/about">About Us</a>
                    </p>
                    <p className="small-text">
                        <a href="/projects">Projects</a>
                    </p>
                    <p className="small-text">
                        <a href="/involvement">Students</a>
                    </p>
                    <p className="small-text">
                        <a href="/work">Companies</a>
                    </p>
                    <p className="small-text">
                        <a href="/contact">Contact Us</a>
                    </p>
                </div>
                <div className="col-sm-2 social-category">
                    <p className="small-text bold">Social</p>
                    <p className="small-text">
                        <a
                            href="https://www.facebook.com/CodeLabDavis"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Facebook
                        </a>
                    </p>
                    <p className="small-text">
                        <a
                            href="https://www.instagram.com/codelabdavis/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Instagram
                        </a>
                    </p>
                    <p className="small-text">
                        <a
                            href="https://www.linkedin.com/company/codelabdavis/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            LinkedIn
                        </a>
                    </p>
                    <p className="small-text">
                        <a
                            href="https://codelabdavis.medium.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Medium
                        </a>
                    </p>
                </div>
                <div className="col-sm-5 mailing-list">
                    <p className="small-text bold">Join our mailing list!</p>
                    <p className="small-text">
                        Stay up to date with our events and opportunities.
                    </p>

                    <div className="inputs">
                        <MailchimpSubscribe
                            url={process.env.REACT_APP_MAILCHIMP_URL}
                            render={({ subscribe, status, message }) => (
                                <div>
                                    <SimpleForm
                                        onSubmitted={(formData) =>
                                            subscribe(formData)
                                        }
                                    />
                                    {status === "sending" && (
                                        <div style={{ color: "blue" }}>
                                            Sending...
                                        </div>
                                    )}
                                    {status === "error" && (
                                        <div
                                            style={{ color: "red" }}
                                            dangerouslySetInnerHTML={{
                                                __html: message,
                                            }}
                                        />
                                    )}
                                    {status === "success" && (
                                        <div style={{ color: "green" }}>
                                            Subscribed!
                                        </div>
                                    )}
                                </div>
                            )}
                        />
                    </div>
                </div>
                <div className="col-sm-3 credits">
                    <div className="credits-box">
                        <div className="title">
                            <p className="bold small-text">Website Credits</p>
                            <p className="tiny boba">Fueled by 🧋</p>
                        </div>
                        <p className="tiny">
                            <b>Design</b> by Edward Chew
                        </p>
                        <p className="tiny">
                            <b>Development</b> by Edward Chew, Ehong Kuo,
                            Parminder Singh, Ninad Swadi
                        </p>
                    </div>
                    <div className="left">
                        <img src={logo} alt="CodeLab Logo" />
                        <p className="tiny">
                            Coded with ❤️ by the Fall 21 cohort
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
