import React from "react";
import error from "@images/error.svg";

const ErrorBanner = ({ message }) => (
    <div className="my-3 flex w-full items-center gap-4 rounded-lg bg-red-500 bg-opacity-70 px-3 py-6 text-white">
        <img src={error} className="flex h-8 w-8" alt="error" />
        {message ? message : "Something went wrong :("}
    </div>
);

export default ErrorBanner;
