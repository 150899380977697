import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Menu } from 'lucide-react';

import "../css/Navigation.css";

function Navigation(props) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    
    return (
        <>
        {/* Define the colors for the svg icons to be manipulated with css */}
        <div id="svg-colors">
            <svg>
                <linearGradient id="red-gradient">
                    <stop stop-color="#F49461"/>
                    <stop offset="100%" stop-color="#E55155"/>
                </linearGradient>
                <linearGradient id="purple-gradient">
                    <stop stop-color="#A453E3"/>
                    <stop offset="100%" stop-color="#D050E5"/>
                </linearGradient>
                <linearGradient id="green-gradient">
                    <stop stop-color="#A3E869"/>
                    <stop offset="100%" stop-color="#48D966"/>
                </linearGradient>
                <linearGradient id="blue-gradient">
                    <stop stop-color="#60CEF4"/>
                    <stop offset="100%" stop-color="#509EE5"/>
                </linearGradient>
            </svg>
        </div>

        {/* Actual content of component */}
        <div id="navigation-wrapper" class="responsive-nav">
            <div className="icon home">
                <NavLink exact to="/" activeClassName="on-home current-page">
                    <svg width="26" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.0645 0L17.5451 33.0304H9.94092L17.4546 0H25.0645Z" fill="white"/>
                        <path id="logo-dot" d="M3.55882 33.3881C5.5243 33.3881 7.11764 31.7893 7.11764 29.8171C7.11764 27.8449 5.5243 26.2461 3.55882 26.2461C1.59334 26.2461 0 27.8449 0 29.8171C0 31.7893 1.59334 33.3881 3.55882 33.3881Z" fill="white"/>
                    </svg>
                </NavLink>
                <div id="home-text" className="popup">Home</div>
            </div>

            <div className="icon about">
                <NavLink exact to="/about" activeClassName="on-about current-page">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 4.06529C16.5396 4.06529 17.0739 4.17157 17.5723 4.37805C18.0708 4.58453 18.5238 4.88717 18.9053 5.2687C19.2868 5.65023 19.5895 6.10317 19.796 6.60166C20.0024 7.10015 20.1087 7.63443 20.1087 8.17399C20.1087 8.71355 20.0024 9.24783 19.796 9.74632C19.5895 10.2448 19.2868 10.6977 18.9053 11.0793C18.5238 11.4608 18.0708 11.7634 17.5723 11.9699C17.0739 12.1764 16.5396 12.2827 16 12.2827C14.9103 12.2827 13.8653 11.8498 13.0947 11.0793C12.3242 10.3087 11.8913 9.26368 11.8913 8.17399C11.8913 7.08429 12.3242 6.03923 13.0947 5.2687C13.8653 4.49817 14.9103 4.06529 16 4.06529ZM16 21.674C21.8109 21.674 27.9348 24.5305 27.9348 25.7827V27.9349H4.06523V25.7827C4.06523 24.5305 10.1891 21.674 16 21.674ZM16 0.3479C11.6761 0.3479 8.17393 3.85007 8.17393 8.17399C8.17393 12.4979 11.6761 16.0001 16 16.0001C20.3239 16.0001 23.8261 12.4979 23.8261 8.17399C23.8261 3.85007 20.3239 0.3479 16 0.3479ZM16 17.9566C10.7761 17.9566 0.347839 20.5783 0.347839 25.7827V29.6957C0.347839 30.7718 1.22827 31.6523 2.30436 31.6523H29.6957C30.7718 31.6523 31.6522 30.7718 31.6522 29.6957V25.7827C31.6522 20.5783 21.2239 17.9566 16 17.9566Z" fill="white"/>
                    </svg>
                </NavLink>
                <div id="about-text" className="popup">About</div>
            </div>

            <div className="icon projects">
                <NavLink exact to="/projects" activeClassName="on-projects current-page">
                    <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.8399 11.3888L24.2014 9.97433L19.8934 1.18214C19.1215 -0.385047 16.8851 -0.404969 16.1066 1.18214L11.7986 9.97433L2.16009 11.3888C0.431615 11.6411 -0.261093 13.786 0.992379 15.0146L7.96564 21.8544L6.31634 31.5165C6.01946 33.263 7.84689 34.5712 9.37745 33.7544L18 29.1923L26.6226 33.7544C28.1531 34.5646 29.9806 33.263 29.6837 31.5165L28.0344 21.8544L35.0077 15.0146C36.2611 13.786 35.5684 11.6411 33.8399 11.3888ZM24.6368 20.7388L26.2004 29.9294L18 25.5931L9.79967 29.9294L11.3632 20.7388L4.72641 14.231L13.8965 12.8896L18 4.52237L22.1035 12.8896L31.2736 14.231L24.6368 20.7388Z" fill="white"/>
                    </svg>
                </NavLink>
                <div id="projects-text" className="popup">Projects</div>
            </div>

            <div className="icon involvement">
                <NavLink exact to="/involvement" activeClassName="on-involvement current-page">
                    <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3408 1.4751C16.4329 0.775128 17.7028 0.403076 19 0.403076C20.2972 0.403076 21.5671 0.775128 22.6592 1.4751L36.7713 10.5209C36.9556 10.6392 37.1073 10.8018 37.2127 10.9938C37.3181 11.1858 37.3738 11.4011 37.3746 11.6201C37.3755 11.8391 37.3216 12.0549 37.2177 12.2477C37.1138 12.4405 36.9634 12.6043 36.78 12.7241L31.25 16.3379V26.0626C31.25 26.3466 31.1579 26.6229 30.9875 26.8501L30.9858 26.8519L30.984 26.8554L30.9787 26.8624L30.963 26.8799L30.9367 26.9149C30.0927 27.8703 29.1127 28.6964 28.0283 29.3666C26.063 30.5776 23.0933 31.7501 19 31.7501C14.9067 31.7501 11.937 30.5776 9.97175 29.3684C9.18531 28.8892 8.45203 28.3278 7.78425 27.6936C7.59173 27.5091 7.40722 27.3164 7.23125 27.1161C7.15581 27.0312 7.08343 26.9437 7.01425 26.8536L7.0125 26.8501C6.84211 26.6229 6.75 26.3466 6.75 26.0626V16.3379L3.25 14.0506V23.4376C3.25 23.7857 3.11172 24.1195 2.86558 24.3657C2.61944 24.6118 2.2856 24.7501 1.9375 24.7501C1.5894 24.7501 1.25556 24.6118 1.00942 24.3657C0.763281 24.1195 0.625 23.7857 0.625 23.4376V11.6251C0.624822 11.4004 0.682334 11.1794 0.792031 10.9833C0.901727 10.7872 1.05993 10.6225 1.2515 10.5051L15.339 1.4751H15.3408ZM22.7083 21.9169C21.6056 22.6371 20.317 23.0206 19 23.0206C17.683 23.0206 16.3944 22.6371 15.2917 21.9169L9.375 18.0511V25.5709C9.96664 26.1698 10.6289 26.6946 11.3472 27.1336C12.938 28.1101 15.437 29.1251 19 29.1251C22.563 29.1251 25.062 28.1101 26.6527 27.1319C27.371 26.6933 28.0332 26.1692 28.625 25.5709V18.0511L22.71 21.9186L22.7083 21.9169ZM21.2435 3.6836C19.8767 2.8086 18.1233 2.8086 16.7565 3.6836L4.3525 11.6356L16.7267 19.7206C18.1093 20.6219 19.8925 20.6219 21.2733 19.7206L33.6457 11.6356L21.2435 3.6836Z" fill="white"/>
                    </svg>
                </NavLink>
                <div id="involvement-text" className="popup">Get Involved</div>
            </div>

            <div className="icon work">
                <NavLink exact to="/work" activeClassName="on-work current-page">
                    <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26.04 0.600098H3.96003C1.93051 0.600098 0.280029 2.25058 0.280029 4.2801V35.5601C0.280029 36.0481 0.473886 36.5161 0.818953 36.8612C1.16402 37.2062 1.63203 37.4001 2.12003 37.4001H27.88C28.368 37.4001 28.836 37.2062 29.1811 36.8612C29.5262 36.5161 29.72 36.0481 29.72 35.5601V4.2801C29.72 2.25058 28.0696 0.600098 26.04 0.600098ZM26.04 33.7201H3.96003V4.2801H26.04V33.7201Z" fill="white"/>
                        <path d="M7.64001 7.95996H13.16V11.64H7.64001V7.95996ZM16.84 7.95996H22.36V11.64H16.84V7.95996ZM7.64001 15.32H13.16V19H7.64001V15.32ZM16.84 15.377H22.36V19H16.84V15.377ZM7.64001 22.68H13.16V26.36H7.64001V22.68ZM16.84 22.68H22.36V26.36H16.84V22.68Z" fill="white"/>
                    </svg>                
                </NavLink>
                <div id="work-text" className="popup">Work with Us</div>
            </div>

            <div className="icon contact">
                <NavLink exact to="/contact" activeClassName="on-contact current-page">
                    <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.3333 27.3332H3.66665C2.78259 27.3332 1.93474 26.982 1.30962 26.3569C0.684502 25.7317 0.333313 24.8839 0.333313 23.9998V3.85484C0.370701 2.99616 0.738328 2.1851 1.35944 1.59101C1.98056 0.996909 2.80715 0.665692 3.66665 0.666505H30.3333C31.2174 0.666505 32.0652 1.01769 32.6903 1.64282C33.3155 2.26794 33.6666 3.11578 33.6666 3.99984V23.9998C33.6666 24.8839 33.3155 25.7317 32.6903 26.3569C32.0652 26.982 31.2174 27.3332 30.3333 27.3332ZM3.66665 7.11317V23.9998H30.3333V7.11317L17 15.9998L3.66665 7.11317ZM4.99998 3.99984L17 11.9998L29 3.99984H4.99998Z" fill="white"/>
                    </svg>
                </NavLink>
                <div id="contact-text" className="popup">Contact</div>
            </div>
        </div>


        <div id="navigation-wrapper" class="mobile-nav">

            <div className = "icon" style = {{marginBottom: "5px"}}>
            <NavLink activeClassName="on-home current-page">
                <button onClick={toggleMenu} style={{ backgroundColor: "#1c1c1c", border: "none" }}>
                    <Menu color="#ffffff"/>
                </button>
            </NavLink>
            </div>
            {isOpen && (
            <div>
            <div className="icon home">
                <NavLink exact to="/" activeClassName="on-home current-page">
                    <svg width="25" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.0645 0L17.5451 33.0304H9.94092L17.4546 0H25.0645Z" fill="white"/>
                        <path id="logo-dot" d="M3.55882 33.3881C5.5243 33.3881 7.11764 31.7893 7.11764 29.8171C7.11764 27.8449 5.5243 26.2461 3.55882 26.2461C1.59334 26.2461 0 27.8449 0 29.8171C0 31.7893 1.59334 33.3881 3.55882 33.3881Z" fill="white"/>
                    </svg>
                </NavLink>
                <div id="home-text" className="popup">Home</div>
            </div>
            <div className="icon about">
                        <NavLink exact to="/about" activeClassName="on-about current-page">
                            <svg width="25" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 4.06529C16.5396 4.06529 17.0739 4.17157 17.5723 4.37805C18.0708 4.58453 18.5238 4.88717 18.9053 5.2687C19.2868 5.65023 19.5895 6.10317 19.796 6.60166C20.0024 7.10015 20.1087 7.63443 20.1087 8.17399C20.1087 8.71355 20.0024 9.24783 19.796 9.74632C19.5895 10.2448 19.2868 10.6977 18.9053 11.0793C18.5238 11.4608 18.0708 11.7634 17.5723 11.9699C17.0739 12.1764 16.5396 12.2827 16 12.2827C14.9103 12.2827 13.8653 11.8498 13.0947 11.0793C12.3242 10.3087 11.8913 9.26368 11.8913 8.17399C11.8913 7.08429 12.3242 6.03923 13.0947 5.2687C13.8653 4.49817 14.9103 4.06529 16 4.06529ZM16 21.674C21.8109 21.674 27.9348 24.5305 27.9348 25.7827V27.9349H4.06523V25.7827C4.06523 24.5305 10.1891 21.674 16 21.674ZM16 0.3479C11.6761 0.3479 8.17393 3.85007 8.17393 8.17399C8.17393 12.4979 11.6761 16.0001 16 16.0001C20.3239 16.0001 23.8261 12.4979 23.8261 8.17399C23.8261 3.85007 20.3239 0.3479 16 0.3479ZM16 17.9566C10.7761 17.9566 0.347839 20.5783 0.347839 25.7827V29.6957C0.347839 30.7718 1.22827 31.6523 2.30436 31.6523H29.6957C30.7718 31.6523 31.6522 30.7718 31.6522 29.6957V25.7827C31.6522 20.5783 21.2239 17.9566 16 17.9566Z" fill="white" />
                            </svg>
                        </NavLink>
                        <div id="about-text" className="popup">About</div>
                    </div><div className="icon projects">
                            <NavLink exact to="/projects" activeClassName="on-projects current-page">
                                <svg width="25" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M33.8399 11.3888L24.2014 9.97433L19.8934 1.18214C19.1215 -0.385047 16.8851 -0.404969 16.1066 1.18214L11.7986 9.97433L2.16009 11.3888C0.431615 11.6411 -0.261093 13.786 0.992379 15.0146L7.96564 21.8544L6.31634 31.5165C6.01946 33.263 7.84689 34.5712 9.37745 33.7544L18 29.1923L26.6226 33.7544C28.1531 34.5646 29.9806 33.263 29.6837 31.5165L28.0344 21.8544L35.0077 15.0146C36.2611 13.786 35.5684 11.6411 33.8399 11.3888ZM24.6368 20.7388L26.2004 29.9294L18 25.5931L9.79967 29.9294L11.3632 20.7388L4.72641 14.231L13.8965 12.8896L18 4.52237L22.1035 12.8896L31.2736 14.231L24.6368 20.7388Z" fill="white" />
                                </svg>
                            </NavLink>
                            <div id="projects-text" className="popup">Projects</div>
                        </div><div className="icon involvement">
                            <NavLink exact to="/involvement" activeClassName="on-involvement current-page">
                                <svg width="25" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3408 1.4751C16.4329 0.775128 17.7028 0.403076 19 0.403076C20.2972 0.403076 21.5671 0.775128 22.6592 1.4751L36.7713 10.5209C36.9556 10.6392 37.1073 10.8018 37.2127 10.9938C37.3181 11.1858 37.3738 11.4011 37.3746 11.6201C37.3755 11.8391 37.3216 12.0549 37.2177 12.2477C37.1138 12.4405 36.9634 12.6043 36.78 12.7241L31.25 16.3379V26.0626C31.25 26.3466 31.1579 26.6229 30.9875 26.8501L30.9858 26.8519L30.984 26.8554L30.9787 26.8624L30.963 26.8799L30.9367 26.9149C30.0927 27.8703 29.1127 28.6964 28.0283 29.3666C26.063 30.5776 23.0933 31.7501 19 31.7501C14.9067 31.7501 11.937 30.5776 9.97175 29.3684C9.18531 28.8892 8.45203 28.3278 7.78425 27.6936C7.59173 27.5091 7.40722 27.3164 7.23125 27.1161C7.15581 27.0312 7.08343 26.9437 7.01425 26.8536L7.0125 26.8501C6.84211 26.6229 6.75 26.3466 6.75 26.0626V16.3379L3.25 14.0506V23.4376C3.25 23.7857 3.11172 24.1195 2.86558 24.3657C2.61944 24.6118 2.2856 24.7501 1.9375 24.7501C1.5894 24.7501 1.25556 24.6118 1.00942 24.3657C0.763281 24.1195 0.625 23.7857 0.625 23.4376V11.6251C0.624822 11.4004 0.682334 11.1794 0.792031 10.9833C0.901727 10.7872 1.05993 10.6225 1.2515 10.5051L15.339 1.4751H15.3408ZM22.7083 21.9169C21.6056 22.6371 20.317 23.0206 19 23.0206C17.683 23.0206 16.3944 22.6371 15.2917 21.9169L9.375 18.0511V25.5709C9.96664 26.1698 10.6289 26.6946 11.3472 27.1336C12.938 28.1101 15.437 29.1251 19 29.1251C22.563 29.1251 25.062 28.1101 26.6527 27.1319C27.371 26.6933 28.0332 26.1692 28.625 25.5709V18.0511L22.71 21.9186L22.7083 21.9169ZM21.2435 3.6836C19.8767 2.8086 18.1233 2.8086 16.7565 3.6836L4.3525 11.6356L16.7267 19.7206C18.1093 20.6219 19.8925 20.6219 21.2733 19.7206L33.6457 11.6356L21.2435 3.6836Z" fill="white" />
                                </svg>
                            </NavLink>
                            <div id="involvement-text" className="popup">Get Involved</div>
                        </div><div className="icon work">
                            <NavLink exact to="/work" activeClassName="on-work current-page">
                                <svg width="25" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.04 0.600098H3.96003C1.93051 0.600098 0.280029 2.25058 0.280029 4.2801V35.5601C0.280029 36.0481 0.473886 36.5161 0.818953 36.8612C1.16402 37.2062 1.63203 37.4001 2.12003 37.4001H27.88C28.368 37.4001 28.836 37.2062 29.1811 36.8612C29.5262 36.5161 29.72 36.0481 29.72 35.5601V4.2801C29.72 2.25058 28.0696 0.600098 26.04 0.600098ZM26.04 33.7201H3.96003V4.2801H26.04V33.7201Z" fill="white" />
                                    <path d="M7.64001 7.95996H13.16V11.64H7.64001V7.95996ZM16.84 7.95996H22.36V11.64H16.84V7.95996ZM7.64001 15.32H13.16V19H7.64001V15.32ZM16.84 15.377H22.36V19H16.84V15.377ZM7.64001 22.68H13.16V26.36H7.64001V22.68ZM16.84 22.68H22.36V26.36H16.84V22.68Z" fill="white" />
                                </svg>
                            </NavLink>
                            <div id="work-text" className="popup">Work with Us</div>
                        </div><div className="icon contact">
                            <NavLink exact to="/contact" activeClassName="on-contact current-page">
                                <svg width="25" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30.3333 27.3332H3.66665C2.78259 27.3332 1.93474 26.982 1.30962 26.3569C0.684502 25.7317 0.333313 24.8839 0.333313 23.9998V3.85484C0.370701 2.99616 0.738328 2.1851 1.35944 1.59101C1.98056 0.996909 2.80715 0.665692 3.66665 0.666505H30.3333C31.2174 0.666505 32.0652 1.01769 32.6903 1.64282C33.3155 2.26794 33.6666 3.11578 33.6666 3.99984V23.9998C33.6666 24.8839 33.3155 25.7317 32.6903 26.3569C32.0652 26.982 31.2174 27.3332 30.3333 27.3332ZM3.66665 7.11317V23.9998H30.3333V7.11317L17 15.9998L3.66665 7.11317ZM4.99998 3.99984L17 11.9998L29 3.99984H4.99998Z" fill="white" />
                                </svg>
                            </NavLink>
                            <div id="contact-text" className="popup">Contact</div>
                        </div>
                </div>
            )}
            </div>

        </>
    );
}

export default Navigation;