import React, { useRef, useState } from "react";
import Slider from "react-slick";

import "@css/ProjectCarousel.css";

import ProjectCard from "./ProjectCard";

import PrevIcon from "@images/ProjectCarousel/PrevIcon.svg";
import NextIcon from "@images/ProjectCarousel/NextIcon.svg";
import LoadingSpinner from "components/LoadingSpinner";
import ErrorBanner from "components/ErrorBanner";

function ProjectCarousel({ title, projects, isPending, isError }) {
    const [curPage, setPage] = useState(1);
    const [locked, setLocked] = useState(false);

    const projectsPerPage = 6;
    const totalPages = Math.ceil(projects.length / projectsPerPage);
    const slideAnimationTime = 500;

    async function prev() {
        // Lock to only allow changing page number once per animation
        // Otherwise page numbers can become out of sync
        if (!locked) {
            setLocked(true);
            sliderRef.current.slickPrev();
            let tmp = ((curPage + totalPages - 2) % totalPages) + 1;
            setPage(tmp);
            await new Promise((resolve) =>
                setTimeout(resolve, slideAnimationTime + 100)
            );
            setLocked(false);
        }
    }

    async function next() {
        if (!locked) {
            setLocked(true);
            sliderRef.current.slickNext();
            let tmp = (curPage % totalPages) + 1;
            setPage(tmp);
            await new Promise((resolve) =>
                setTimeout(resolve, slideAnimationTime + 100)
            );
            setLocked(false);
        }
    }

    // Returns a list of dispatchEvent, where each div is a row of projectsPerPage cols
    function generatePages() {
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            let curPageContent = [];

            let lastProjectIndex = i * projectsPerPage;
            let firstProjectIndex = lastProjectIndex - projectsPerPage;
            let curProjects = projects.slice(
                firstProjectIndex,
                lastProjectIndex
            );

            curPageContent = curProjects.map((project) => (
                <div className="col-md-6 project-card">
                    <ProjectCard
                        link={project.link}
                        logo={project.img}
                        title={project.title}
                        description={project.description}
                        term={project.term}
                    />
                </div>
            ));

            pages.push(
                <div>
                    <div className="row">{curPageContent}</div>
                </div>
            );
        }
        return pages;
    }

    var settings = {
        speed: slideAnimationTime,
        arrows: false,
    };

    const sliderRef = useRef();
    return (
        <div className="carousel-wrapper">
            <div className="carousel-heading">
                <h2>{title}</h2>
                <div className="carousel-controls">
                    <button onClick={prev}>
                        <img src={PrevIcon} alt="Prev" />
                    </button>
                    <p className="small-text">{curPage + "/" + totalPages}</p>
                    <button onClick={next}>
                        <img src={NextIcon} alt="Next" />
                    </button>
                </div>
            </div>
            {isPending ? (
                <div className="pb-10">
                    <LoadingSpinner />
                </div>
            ) : isError ? (
                <ErrorBanner message="Something went wrong :(" />
            ) : (
                <div className="carousel-container">
                    <Slider {...settings} ref={sliderRef}>
                        {generatePages()}
                    </Slider>
                </div>
            )}
        </div>
    );
}

export default ProjectCarousel;
