import { cn } from "lib/utils";
import "@css/ProjectCard.css";

export default function TermBadge({ color, term, className }) {
    const colorClassWrapper = color === "green" ? "term-green" : "term-blue";

    const colorClassText =
        color === "green"
            ? "bg-gradient-to-r from-lime-300 to-green-500"
            : "bg-gradient-to-r from-cyan-300 to-sky-500";

    return (
        <div
            className={cn(
                "flex content-center rounded-full border-[1px] border-solid border-transparent bg-clip-text px-3 py-1",
                colorClassWrapper,
                className
            )}
        >
            <p
                className={cn(
                    "m-0 bg-clip-text text-xs text-transparent",
                    colorClassText
                )}
            >
                {term}
            </p>
        </div>
    );
}
