import "@css/Projects.css";
import "@css/App.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ProjectCarousel from "./ProjectCarousel";
import CalloutCard from "@components/CalloutCard.js";
import cowImage from "@images/Projects/ucdCow.png";
import useGetProjects from "./hooks/useGetProjects";

function Projects() {
    // Projects Data
    const { clientProjects, sparkProjects, upkeepProjects, productProjects, isPending, isError } = useGetProjects();

    return (
        <div id="projects-wrapper">
            <div className="narrow">
                <div className="row">
                    <div className="col-md-6 projects">
                        <h1 className="">Projects</h1>
                        <div className="blue-gradient-rectangle"></div>
                        <p className="projects-subtitle-p-tags">
                            CodeLab offers two types of projects: Spark and Client. <br /> <br />

                            Spark team members create large-scale software projects under the guidance of experienced developers and designers. <br /> <br />

                            Client team members create software for tech startups, UC Davis professors, and nonprofits.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="cow-container">
                            <img src={cowImage} className="aggie-cow" alt="aggie-cow" />
                        </div>
                    </div>
                </div>
                <div className="project-carousel">
                    <ProjectCarousel title="Client Projects" projects={clientProjects} isError={isError} isPending={isPending} />
                </div>
            </div>
            <div className="wide work-with-us-cc">
                <CalloutCard
                    colorScheme="light" // color scheme takes in class names
                    title="🚀 Interested in working with us?"
                    description="We are always looking for companies and organizations to work with. If you are interested, please reach out!"
                    buttonTitle="Learn More"
                    buttonLink="/work"
                />
            </div>
            <div className="narrow spark-upkeep">
                <div className="project-carousel">
                    <ProjectCarousel title="Spark Projects" projects={sparkProjects} isError={isError} isPending={isPending}/>
                </div>
                <div className="project-carousel">
                    <ProjectCarousel title="Upkeep Projects" projects={upkeepProjects} isError={isError} isPending={isPending}/>
                </div>
                <div className="project-carousel">
                    <ProjectCarousel title="Product Projects" projects={productProjects} isError={isError} isPending={isPending}/>
                </div>
            </div>
        </div>
    );
}

export default Projects;
