import "../css/Involvement.css";

import CalloutCard from "./CalloutCard";
import ContentCard from "./ContentCard";
import ContentCardBullets2 from "./ContentCardWithBullets2";

import GroupPhoto from "../images/Involvement/group-photo.jpg";
import WWDPhoto1 from "../images/Involvement/WWDP1.jpg";
import WWDPhoto2 from "../images/Involvement/WWDP2.jpg";
import FAQPhoto from "../images/Involvement/FAQP.jpg";
import BTP_1 from "../images/Involvement/BTP1.jpg";
import BTP_2 from "../images/Involvement/BTP2.jpg";
import BTP_3 from "../images/Involvement/BTP3.jpg";
import BTP_4 from "../images/Involvement/BTP4.jpg";
import BTP_5 from "../images/Involvement/BTP5.jpg";

function Involvement() {
    return (
        <div id="involvement-wrapper">
            <div className="">
                <div className="narrow">
                    <h1>Get Involved</h1>
                    <div className="green-rectangle"></div>
                </div>
            </div>
            <div className="general narrow">
                <div className="row">
                    <div className="col-md-6">
                        <p className="about-involvement">
                            CodeLab recruits new members twice a year, at the
                            beginning of Fall Quarter and at the beginning of
                            Winter Quarter. We are seeking highly motivated
                            individuals excited about working on development or
                            design projects. If you think you’d be a good fit,
                            please apply! We look forward to getting to know
                            you.
                        </p>
                        <CalloutCard
                            colorScheme="light"
                            title="⭐️ Apply Now"
                            description="Applications for Winter/Spring 2024 are now closed. We will be recruiting again in Fall 2024."
                            buttonLink=""
                            buttonTitle=""
                            // buttonTitle="Apply Here"
                        />
                    </div>
                    <div className="col-md-6 involvement-img">
                        <img src={GroupPhoto} alt="group" />
                    </div>
                </div>
            </div>
            <div className="project-teams-background"></div>
            <div className="project-teams narrow">
                <h2>Projects</h2>
                <p className="description">
                    We offer two project types for students, each with their own
                    set of responsibilities and requirements. Please review the
                    details to see which is the best fit for you.
                </p>
                <div className="row">
                    <div className="col-md-6 content-card">
                        <ContentCard
                            colorScheme={"blue"}
                            title={"🐣 Spark"}
                            description={
                                "Spark team associates build large-scale projects, often tools to help UC Davis students. Spark team associates receive support from more experienced students to develop their skills."
                            }
                        />
                    </div>
                    <div className="col-md-6 content-card">
                        <ContentCard
                            colorScheme={"purple"}
                            title={"🚀 Client"}
                            description={
                                "Client team associates work with an industry, nonprofit, or faculty stakeholder. Associates on these teams should have extensive design and/or development experience."
                            }
                        />
                    </div>
                </div>
                <p className="red-text-callout">
                    * For more details on each program/project type, refer to
                    the current cycle's application page. *
                </p>
            </div>
            <div className="requirements narrow">
                <h2>Requirements</h2>
                <p>
                    For full descriptions of each role’s requirements and
                    responsibilities, please visit the current cycle’s
                    application page on Notion.
                </p>
                <div className="bullets">
                    <ContentCardBullets2
                        colorScheme={"dark"}
                        title={"💻 Developers"}
                        subtitle1={"Client Project"}
                        subtitle2={"Spark Project"}
                        content1={[
                            "Has several strong examples of programming experience beyond classwork.",
                            "Completed a large-scale coding project (personal project, industry experience, CodeLab project).",
                            "Expert front-end, back-end, or full-stack programming skills.",
                        ]}
                        content2={[
                            "Shows a passion for Computer Science beyond classwork.",
                            "Foundational knowledge of one or more programming technologies (React, Express.js, AWS, MongoDB, etc.)",
                        ]}
                    />
                </div>
                <div className="bullets">
                    <ContentCardBullets2
                        colorScheme={"dark"}
                        title={"🎨 Designers"}
                        subtitle1={"Client Project"}
                        subtitle2={"Spark Project"}
                        content1={[
                            "Has worked with with design software such as Figma, Adobe Suite, or Microsoft Paint.",
                            "Completed a UX design project (DES 112 project, side project, industry experience, CodeLab project).",
                        ]}
                        content2={[
                            "Has worked with with design software such as Figma, Adobe Suite, or Microsoft Paint.",
                            "Design experience, especially in UI/UX design.",
                        ]}
                    />
                </div>
                <div className="bullets">
                    <ContentCardBullets2
                        colorScheme={"dark"}
                        title={"🎉 Project Managers"}
                        subtitle1={"Client Project"}
                        subtitle2={"Spark Project"}
                        content1={[
                            "Software/design work experience or sizable project experience.",
                            "Demonstrates strong communication and/or leadership skills from clubs, school, and/or work.",
                        ]}
                        content2={[
                            "Demonstrates strong communication and/or leadership skills from clubs, school, and/or work.",
                        ]}
                    />
                </div>
            </div>
            <div className="what-we-do row desktop">
                <div className="col-md-6 find-community">
                    <img src={WWDPhoto1} alt="board" />
                    <h2>Find a social and professional community.</h2>
                </div>
                <div className="col-md-6 build-projects">
                    <h2>Build meaningful, real-word projects.</h2>
                    <img src={WWDPhoto2} alt="hardworkers" />
                </div>
            </div>
            <div className="what-we-do row mobile">
                <div className="col-md-6 find-community">
                    <h2>Find a social and professional community.</h2>
                    <img src={WWDPhoto1} alt="board" />
                </div>
                <div className="col-md-6 build-projects">
                    <h2>Build meaningful, real-word projects.</h2>
                    <img src={WWDPhoto2} alt="hardworkers" />
                </div>
            </div>
            <div className="the-term narrow">
                <h2>The Term</h2>
                <p>
                    Each year in CodeLab has two terms, a Fall term and a
                    Winter/Spring term. The Fall term runs approximately 6 weeks
                    in fall quarter, and the Winter/Spring term runs
                    approximately 16 weeks across winter and spring quarter.
                    This means the scale of projects will vary between terms.
                    Each term begins with a 1 or 2 week long technical boot
                    camp, and the rest of the term is dedicated to working on
                    the projects and preparing the presentations. Recruitment
                    occurs at the beginning of Fall and Winter quarter.
                </p>
            </div>
            <div className="resources">
                <CalloutCard
                    colorScheme="light"
                    title="💡 Explore Resources"
                    description="Each term begins with a technical boot camp to learn the development tech stack and UX skills. View some of our learning resources below!"
                    buttonLink="https://www.youtube.com/channel/UCAMYCupe0W1ICln7CzYU1Zg"
                    buttonTitle="View Resources"
                />
            </div>
            <div className="FAQ-background"></div>
            <div className="FAQ narrow desktop">
                <div className="row">
                    <h2>Frequently Asked Questions</h2>
                    <div className="col-md-6 ">
                        <p className="bold">
                            Do I need to be a computer science or design major
                            to apply to CodeLab?
                        </p>
                        <p>
                            Nope! We are open to undergraduate students at UC
                            Davis of any major. However, you do need some
                            programming or design experience to apply. Detailed
                            requirements can be found above or on the current
                            cycle’s application page.
                        </p>
                        <hr />
                        <p className="bold">
                            What is the time commitment like?
                        </p>
                        <p>
                            Project managers are expected to put in 12+ hours a
                            week, and developers and designers are expected to
                            put in 10+ hours a week. Depending on the part of
                            the project you are working on, this may fluctuate
                            throughout the term. Being a CodeLab member can be
                            time-consuming. Before applying, please consider
                            your other commitments to make sure you will be able
                            to put in the time for CodeLab.
                        </p>
                        <hr />
                        <p className="bold">
                            What does the recruitment process look like?
                        </p>
                        <p>
                            The process currently involves a written application
                            and a single round of interviews. Don’t be nervous
                            about interviews. We just want to get to know you
                            and learn about your past experiences. It will not
                            be technical.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={FAQPhoto} alt="" />
                        <p className="bold">
                            What types of projects do developers work on?
                        </p>
                        <p>
                            Anything computer science, whether it be web
                            development or machine learning. Projects are
                            largely reliant on the needs of our clients from
                            term to term. Mentored projects can often be more
                            flexible in the computer science fields they
                            involve.
                        </p>
                        <hr />
                        <p className="bold">
                            What types of projects do designers work on?
                        </p>
                        <p>
                            Designers and developers work on the same teams,
                            though not all teams will have designers. Designers
                            will generally be part of web or mobile development
                            projects, which are heavy on user interfaces.
                        </p>
                    </div>
                </div>
            </div>
            <div className="FAQ narrow mobile">
                <div className="row">
                    <h2>Frequently Asked Questions</h2>
                    <div className="col-md-6">
                        <p className="bold">
                            Do I need to be a computer science or design major
                            to apply to CodeLab?
                        </p>
                        <p>
                            Nope! We are open to undergraduate students at UC
                            Davis of any major. However, you do need some
                            programming or design experience to apply. Detailed
                            requirements can be found above or on the current
                            cycle’s application page.
                        </p>
                        <hr />
                        <p className="bold">
                            What is the time committment like?
                        </p>
                        <p>
                            Project managers are expected to put in 12+ hours a
                            week, and developers and designers are expected to
                            put in 10+ hours a week. Depending on the part of
                            the project you are working on, this may fluctuate
                            throughout the term. Being a CodeLab member can be
                            time-consuming. Before applying, please consider
                            your other commitments to make sure you will be able
                            to put in the time for CodeLab.
                        </p>
                        <hr />
                        <p className="bold">
                            What does the recruitment process look like?
                        </p>
                        <p>
                            The process currently involves a written application
                            and a single round of interviews. Don’t be nervous
                            about interviews. We just want to get to know you
                            and learn about your past experiences. It will not
                            be technical.
                        </p>
                        <hr />
                        <p className="bold">
                            What types of projects do developers work on?
                        </p>
                        <p>
                            Anything computer science, whether it be web
                            development or machine learning. Projects are
                            largely reliant on the needs of our clients from
                            term to term. Mentored projects can often be more
                            flexible in the computer science fields they
                            involve.
                        </p>
                        <hr />
                        <p className="bold">
                            What types of projects do designers work on?
                        </p>
                        <p>
                            Designers and developers work on the same teams,
                            though not all teams will have designers. Designers
                            will generally be part of web or mobile development
                            projects, which are heavy on user interfaces.
                        </p>
                        <hr />
                        <img src={FAQPhoto} alt="" />
                    </div>
                </div>
            </div>
            <div className="beyond-the-projects narrow desktop">
                <div className="row">
                    <div className="col-md-4">
                        <img src={BTP_1} alt="Two associates at bowling" />
                        <img
                            src={BTP_2}
                            alt="Two associates playing video games"
                            className="left"
                        />
                    </div>
                    <div className="col-md-8 text">
                        <h2>Beyond the Projects</h2>
                        <p>
                            CodeLab isn’t just about coding projects; we’re a
                            community.
                        </p>
                        <p>
                            You will have opportunities to meet new people in
                            the organization through events like socials,
                            dinners, and day trips. We also host other events
                            and workshops to help you grow professionally and
                            network with other CodeLab members. We hope that
                            you’ll both challenge yourself when working on the
                            projects and also have a fun experience being a
                            member.
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                <img src={BTP_3} alt="Omid and Abhay" />
                            </div>
                            <div className="col-md-6">
                                <img
                                    src={BTP_4}
                                    alt="Scavenger Hunt Event"
                                    className="right"
                                />
                                <img src={BTP_5} alt="Zoom" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="beyond-the-projects narrow mobile">
                <div className="row">
                    <div className="col-md-4">
                        <h2>Beyond the Projects</h2>
                        <p>
                            CodeLab isn’t just about coding projects; we’re a
                            community.
                        </p>
                        <p>
                            You will have opportunities to meet new people in
                            the organization through events like bondings,
                            dinners, and day trips. We also host other events
                            and workshops to help you grow professionally and
                            network with other CodeLab members. We hope that
                            you’ll both challenge yourself when working on the
                            projects and also have a fun experience being a
                            member.
                        </p>
                        <img src={BTP_1} alt="Two associates at bowling" />
                        <img
                            src={BTP_2}
                            alt="Two associates playing video games"
                            className="left"
                        />
                        <img src={BTP_3} alt="Omid and Abhay" />
                        <img
                            src={BTP_4}
                            alt="Scavenger Hunt Event"
                            className="right"
                        />
                        <img src={BTP_5} alt="Zoom" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Involvement;
