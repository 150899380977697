import "@css/App.css";
import "@css/ProjectCard.css";
import TermBadge from "./TermBadge";

function ProjectCard({ link, logo, title, description, term }) {
    function getTermColor(term) {
        let data = term.split(" ");
        if (data[0] === "Winter/Spring") {
            return "green";
        } else {
            return "blue";
        }
    }

    return (
        <a
            href={link}
            target="_blank"
            className={link && "enabled"}
            style={link ? null : { pointerEvents: "none" }}
            rel="noreferrer"
        >
            <div className="flex max-h-56 min-h-[12.5rem] overflow-hidden rounded border-2 border-solid border-neutral-400 bg-neutral-700 transition-all duration-200 ease-in-out hover:-translate-x-0.5 hover:bg-neutral-600">
                {/* <div
                    style={{ backgroundImage: `url(${logo})` }}
                    alt="Company logo"
                    className="proj-logo"
                /> */}
                <img
                    src={logo}
                    alt="Company logo"
                    className="proj-logo max-h-56"
                />
                <div className="flex w-full flex-col justify-between p-[0.75rem] lg:px-6 lg:py-5">
                    <div>
                        <p className="mb-2 font-bold text-white">{title}</p>
                        <p className="description text-xs text-white lg:text-sm">
                            {description}
                        </p>
                    </div>
                    <div className="flex content-end items-end justify-between">
                        <TermBadge color={getTermColor(term)} term={term} />
                        <p className="m-0 flex text-sm text-neutral-400">
                            Read More &gt;
                        </p>
                    </div>
                </div>
            </div>
        </a>
    );
}

export default ProjectCard;
