import "@css/About.css";
import "@css/App.css";
import Member from "./Member";
import PastMember from "./PastMember";
import CalloutCard from "../CalloutCard";

import FFBlob from "@images/About/Founding Fathers/FoundingTeamBlob.png";

import Logo1 from "@images/About/CompanyLogos/Logo1.png";
import Logo2 from "@images/About/CompanyLogos/Logo2.png";
import Logo3 from "@images/About/CompanyLogos/Logo3.png";
import Logo4 from "@images/About/CompanyLogos/Logo4.png";
import Logo5 from "@images/About/CompanyLogos/Logo5.png";
import Logo6 from "@images/About/CompanyLogos/Logo6.png";
import Logo7 from "@images/About/CompanyLogos/Logo7.png";
import Logo8 from "@images/About/CompanyLogos/Logo8.png";
import Logo9 from "@images/About/CompanyLogos/Logo9.png";
import Logo10 from "@images/About/CompanyLogos/Logo10.png";
import Logo11 from "@images/About/CompanyLogos/Logo11.png";
import Logo12 from "@images/About/CompanyLogos/Logo12.png";
import Logo13 from "@images/About/CompanyLogos/Logo13.png";
import Logo14 from "@images/About/CompanyLogos/Logo14.png";
import Logo15 from "@images/About/CompanyLogos/Logo15.png";
import Logo16 from "@images/About/CompanyLogos/Logo16.png";
import Logo17 from "@images/About/CompanyLogos/Logo17.png";

import useGetMembers from "./hooks/useGetMembers";
import LoadingSpinner from "components/LoadingSpinner";
import ErrorBanner from "components/ErrorBanner";

function About() {
    const { isPending, isError, currentMembers, prevMembers } = useGetMembers();

    return (
        <div id="about-wrapper">
            <div className="heading narrow">
                <h1>About Us</h1>
                <div className="purple-rectangle"></div>
            </div>
            <div className="narrow">
                <div className="row how-we-started">
                    <div className="col-sm-6 founding-fathers">
                        <img src={FFBlob} alt="Edward" />
                    </div>
                    <div className="col-sm-6 how-we-started-text">
                        <h2>How We Started</h2>
                        <p>
                            Hey, we're CodeLab. The four of us—Essam, Omid,
                            James, and Edward—went through our first year at UC
                            Davis feeling like there wasn't enough of a computer
                            science community. With heavily theoretical
                            coursework, it could be difficult to develop the
                            skills associated with real world applications of
                            our coding knowledge.
                        </p>
                        <p>
                            And so, we built CodeLab, a software development and
                            UX design organization at UC Davis. Members work on
                            projects with real companies to experience industry
                            level projects. Today we're a community of students
                            passionate about technology who are always working
                            to learn and grow.
                        </p>
                    </div>
                </div>
            </div>
            <div className="diagonal-box">
                <div className="content where-we-go">
                    <h2>Where We Go</h2>
                    <p>
                        CodeLab strives to prepare members for their future
                        careers. Members have gone on to work in various
                        industries through internships and full-time employment.
                    </p>
                    <div
                        className="row align-items-center justify-content-center companies"
                        align="center"
                    >
                        <div className="col-6 col-lg-2">
                            <img src={Logo1} alt="Microsoft" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo2} alt="Twitch" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo3} alt="Principal" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo4} alt="Nasa" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo5} alt="Strivr" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo6} alt="Amazon" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo7} alt="Boeing" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo8} alt="Intel" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo9} alt="LibreTexts" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo10} alt="TMobile" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo11} alt="Zendesk" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo12} alt="Zendesk" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo13} alt="CA ISO" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo14} alt="IBM" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo15} alt="Toyota" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo16} alt="Cisco" />
                        </div>
                        <div className="col-6 col-lg-2">
                            <img src={Logo17} alt="Principal" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-team narrow">
                <h2>Our Team</h2>
                <div className="row">
                    {isPending ? (
                        <LoadingSpinner />
                    ) : isError ? (
                        <ErrorBanner />
                    ) : (
                        currentMembers.map((member) => {
                            return (
                                <div className="col-6 col-md-3">
                                    <Member
                                        name={member.name}
                                        title={member.title}
                                        img={member.img}
                                        linkedin={member.linkedin}
                                        role={member.role}
                                    />
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
            <div className="past-members narrow">
                <h2>Past Members</h2>
                <div className="row">
                    {isPending ? (
                        <LoadingSpinner />
                    ) : isError ? (
                        <ErrorBanner />
                    ) : (
                        prevMembers.map((member) => {
                            return (
                                <div className="col-4 col-md-2">
                                    <PastMember
                                        name={member.name}
                                        img={member.img}
                                        linkedin={member.linkedin}
                                        last_cohort={member.last_cohort}
                                    />
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
            <div className="callout-card">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-7">
                        <CalloutCard
                            colorScheme={"dark"}
                            title={"⭐️ Get Involved"}
                            description={
                                "UC Davis students, we would love for you to join us! We recruit new members twice a year, once in Fall Quarter and once in Winter Quarter."
                            }
                            buttonLink={"/involvement"}
                            buttonTitle={"Learn More"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
