import "../css/MobileError.css";
import "../css/App.css";

import Logo from "../images/MobileError/Logo.svg";
import Photo from "../images/MobileError/Photo.jpg"

function MobileError() {
    return(
        <div id="mobile-error-wrapper">
            <img className="photo" src={Photo} alt="CodeLab cohort"/>
            <img className="logo" src={Logo} alt="CodeLab logo"/>
            <div className="shadow-box">
                <p className="bold">Welcome to CodeLab UC Davis!</p>
                <p>This site was imagined for a desktop experience. Visit us again at www.codelabdavis.com.</p>
                <p>Or, find our social media links at our <a href="https://linktr.ee/codelabdavis">Linktree</a>.</p>
            </div>
        </div>
    );
}

export default MobileError;