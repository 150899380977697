import "../css/Contact.css";
import "../css/App.css";

function Contact() {
    return (
        <div id="contact-wrapper">
            <div className="row">
                <div className="inner-contact-wrapper narrow">
                    <div className="col-lg-12 title">
                        <h1>Contact Us</h1>
                        <div className="short-underline"></div>
                    </div>
                    <div className="col-12 directions">
                        <p>
                            We would love to chat! Please email us at{" "}
                            <a href="mailto:codelabdavis@gmail.com">
                                codelabdavis@gmail.com
                            </a>
                            .
                        </p>
                        <p>We will be in touch soon!</p>
                    </div>
                </div>
                {/* <div className="col-sm-8 directions big">
                    <form>
                        <div class="row">
                            <div class="col-md-6 col-12 left">
                                <label for="name" class="label">Name</label>
                                <input type="text" class="form-control" placeholder="Billy Brown"></input>
                            </div>
                            <div class="col-md-6 col-12 right">
                                <label for="email" class="label">Email</label>
                                <input type="text" class="form-control"></input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-12 message-field">
                                <label for="message" class="label">Message</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-12">
                                <button type="submit" class="button">Send Message</button>
                            </div>
                            <div class="col-lg-12 col-12">
                                <p class="subscribed-text">Message sent.</p>
                            </div>
                        </div>
                    </form>
                </div> */}
            </div>
        </div>
    );
}

export default Contact;
