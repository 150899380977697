import { useQuery } from "@tanstack/react-query";
import axios from "axios";

function useGetMembers() {
    const currTerm = "WSQ 24";

    const { isPending, isError, data } = useQuery({
        queryKey: ["member"],
        queryFn: async () => {
            const resp = await axios.get(
                `${process.env.REACT_APP_API_URL}/member/website`
            );
            return resp.data;
        },
    });

    // Function to parse active vs non-active members
    function parseCurrentMembers(members) {
        var current = [];
        for (var i = 0; i < members.length; i++) {
            if (
                members[i]["active"] &&
                members[i]["last_cohort"] === currTerm
            ) {
                current.push(members[i]);
            }
        }
        return sortCurrentMembers(current);
    }

    function parsePrevMembers(members) {
        var prev = [];
        for (var i = 0; i < members.length; i++) {
            if (
                members[i]["active"] &&
                members[i]["last_cohort"] !== currTerm
            ) {
                prev.push(members[i]);
            }
        }
        return sortPastMembers(prev);
    }

    // Sort by role, then by last name
    function sortCurrentMembers(list) {
        list.sort((a, b) => sortByRole(a, b));
        return list;
    }

    // Sort by term, then by last name
    function sortPastMembers(list) {
        list.sort((a, b) => sortByTerm(a, b));
        return list;
    }

    function sortByTerm(a, b) {
        let a_term = a.last_cohort.split(" ")[0];
        let a_year = parseInt(a.last_cohort.split(" ")[1]);
        let b_term = b.last_cohort.split(" ")[0];
        let b_year = parseInt(b.last_cohort.split(" ")[1]);
        if (a_year < b_year) return 1;
        else if (a_year > b_year) return -1;
        else if (a_term === "WSQ" && b_term === "FQ") return 1;
        else if (a_term === "FQ" && b_term === "WSQ") return -1;
        else return 0;
    }

    function sortByRole(a, b) {
        if (a.title === "President") return -1;
        else if (b.title === "President") return 1;
        else if (a.role < b.role) return 1;
        else if (a.role > b.role) return -1;
        else return 0;
    }

    return {
        isPending,
        isError,
        currentMembers: isPending || isError ? [] : parseCurrentMembers(data),
        prevMembers: isPending || isError ? [] : parsePrevMembers(data),
    };
}

export default useGetMembers;
