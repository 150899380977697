function ContentCard(props) {
    return(
        <div id="content-card-wrapper">
            <div className={props.colorScheme}>
                <h3>{props.title}</h3>
                <p>{props.description}</p>
            </div>
        </div>
    )
  }
  export default ContentCard;

