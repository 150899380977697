import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export default function useGetProjects() {
    const {
        data: allProjects,
        isPending,
        isError,
    } = useQuery({
        queryKey: ["project"],
        queryFn: async () => {
            const resp = await axios.get(
                `${process.env.REACT_APP_API_URL}/project/website`
            );
            return resp.data;
        },
    });

    function getClientProjects(projects) {
        var clientProjects = [];
        for (var i = 0; i < projects.length; i++) {
            if (projects[i]["type"] === "Client") {
                clientProjects.push(projects[i]);
            }
        }
        return sortProjectsByTerm(clientProjects);
    }

    function getSparkProjects(projects) {
        var sparkProjects = [];
        for (var i = 0; i < projects.length; i++) {
            if (projects[i]["type"] === "Spark") {
                sparkProjects.push(projects[i]);
            }
        }

        return sortProjectsByTerm(sparkProjects);
    }

    function getUpkeepProjects(projects) {
        var upkeepProjects = [];
        for (var i = 0; i < projects.length; i++) {
            if (projects[i]["type"] === "Upkeep") {
                upkeepProjects.push(projects[i]);
            }
        }

        return sortProjectsByTerm(upkeepProjects);
    }

    function getProductProjects(projects) {
        const productProjects = projects.filter(
            (project) => project.type === "Product"
        );
        return sortProjectsByTerm(productProjects);
    }

    function sortProjectsByTerm(projects) {
        return projects.sort((x, y) => {
            if (x["term"].split(" ")[1] > y["term"].split(" ")[1]) {
                return -1;
            } else if (x["term"].split(" ")[1] < y["term"].split(" ")[1]) {
                return 1;
            } else {
                if (
                    x["term"].split(" ")[0] === "Fall" &&
                    y["term"].split(" ")[0] === "Winter/Spring"
                ) {
                    return -1;
                } else if (
                    y["term"].split(" ")[0] === "Fall" &&
                    x["term"].split(" ")[0] === "Winter/Spring"
                ) {
                    return 1;
                } else {
                    return x["title"].localeCompare(y["title"]);
                }
            }
        });
    }

    return {
        clientProjects:
            isPending || isError ? [] : getClientProjects(allProjects),
        sparkProjects:
            isPending || isError ? [] : getSparkProjects(allProjects),
        upkeepProjects:
            isPending || isError ? [] : getUpkeepProjects(allProjects),
        productProjects:
            isPending || isError ? [] : getProductProjects(allProjects),
        isPending,
        isError,
    };
}
