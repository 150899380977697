import "../css/App.css";
import { Route, Routes } from "react-router-dom";
import MobileError from "./MobileError";
import ScrollToTop from "./ScrollToTop";
import Navigation from "./Navigation";
import Home from "./Home.js";
import About from "./About";
import Projects from "./Projects";
import Involvement from "./Involvement.js";
import Work from "./Work";
import Contact from "./Contact.js";
import Footer from "./Footer";
import { RouterProvider } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
    const router = createBrowserRouter([{ path: "*", Component: Root }]);

    return <RouterProvider router={router} />;
}

const queryClient = new QueryClient();

function Root() {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <div id="mobile-error">
                    <MobileError />
                </div>
                <div id="site-container">
                    <ScrollToTop />
                    <Navigation />
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/about" element={<About />} />
                        <Route exact path="/projects" element={<Projects />} />
                        <Route
                            exact
                            path="/involvement"
                            element={<Involvement />}
                        />
                        <Route exact path="/work" element={<Work />} />
                        <Route exact path="/contact" element={<Contact />} />
                    </Routes>
                    <Footer />
                </div>
            </QueryClientProvider>
        </>
    );
}

export default App;
