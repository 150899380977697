import "../css/Home.css";
import "../css/App.css";
import ProjectCard from "./Projects/ProjectCard";
import CalloutCard from "../components/CalloutCard";

import AboutPhoto from "../images/Home/AboutPhoto.jpg";
import Diagonal1 from "../images/Home/Diagonal1.png";
import Diagonal2 from "../images/Home/Diagonal2.png";
import Rectangle1 from "../images/Home/Rectangle1.jpg";
import Rectangle2 from "../images/Home/Rectangle2.jpg";
import Square1 from "../images/Home/Square1.jpg";
import Square2 from "../images/Home/Square2.jpg";
import Square3 from "../images/Home/Square3.jpg";

import { useState, useEffect } from "react";
import axios from "axios";

function Home(props) {
    // Projects Data
    const [allProjects, update] = useState({ data: [] });

    // On load, retrieve all project data
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/projects`)
            .then((res) => {
                update({ data: res.data });
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // Function to parse projects to show on page
    // TODO: Get a fixed method to select which/how many projects to display
    function parseProjects(projects) {
        var toShow = [];
        for (var i = 0; i < projects.length; i++) {
            if (projects[i]["title"] === "BlockScience Labs - Subway Map") {
                toShow.push(projects[i]);
            } else if (projects[i]["title"] === "Golf Guru") {
                toShow.push(projects[i]);
            } else if (projects[i]["title"] === "FindAMoo") {
                toShow.push(projects[i]);
            } else if (
                projects[i]["title"] === "AggieExplorer" &&
                projects[i]["type"] === "Spark"
            ) {
                toShow.push(projects[i]);
            } else if (projects[i]["title"] === "Trytale") {
                toShow.push(projects[i]);
            } else if (projects[i]["title"] === "StarTree") {
                toShow.push(projects[i]);
            }
        }
        return toShow;
    }

    return (
        <div id="home-wrapper">
            <div className="row narrow">
                <div className="col-md-12 slogan">
                    <h1 id="codelab-at-davis">CodeLab at Davis</h1>
                </div>
                <div className="col-md-12 ">
                    <h2 className="student-run-software">
                        A student-run software and design agency
                    </h2>
                </div>
                <div className="col-md-2 intro-buttons">
                    <a className="button" href="\involvement">
                        Join Us
                    </a>
                    <a
                        className="work-with-us-button button bordered"
                        href="/work"
                    >
                        Work with Us
                    </a>
                </div>
            </div>

            <img
                class="group-pic full-width"
                src={Diagonal1}
                alt="Associates watching presentation"
            />
            <div className="narrow">
                <div className="row">
                    <div className="col-md-12">
                        <h1 id="community">Software, design, and community.</h1>
                    </div>
                    <div className="col-md-6">
                        <img
                            id="home-pic"
                            src={AboutPhoto}
                            alt="Codelab group"
                        />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <p id="codelab-description">
                            CodeLab is an organization at UC Davis working to
                            provide students with real-world experience in the
                            software industry. CodeLab members develop their
                            technical skills by working in interdisciplinary
                            teams on projects over the course of one or more
                            academic quarters.
                        </p>
                    </div>
                </div>
            </div>

            <div className="row codelab-statistics align-items-center wide text-center">
                <div className="col-md-3">
                    <h1 id="first-stat">21</h1>
                    <p className="bold">completed projects</p>
                </div>
                <div className="col-md-3">
                    <h1 id="second-stat">15</h1>
                    <p className="bold">industry clients</p>
                </div>
                <div className="col-md-3">
                    <h1 id="third-stat">180</h1>
                    <p className="bold">members &#38; alumni</p>
                </div>
                <div className="col-md-3">
                    <h1 id="fourth-stat">6</h1>
                    <p className="bold">project terms</p>
                </div>
            </div>
            <div className="narrow">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="we-build-amazing-things">
                            We build amazing things.
                        </h1>
                        <p id="we-build-amazing-things-description">
                            During our two terms each year, CodeLab members work
                            in teams on projects, many with industry clients.
                            Here are just a few of our favorite projects.
                        </p>
                    </div>
                </div>
            </div>
            <div className="narrow">
                <div className="row">
                    {parseProjects(allProjects.data).map((project) => {
                        return (
                            <div className="col-md-6 project-cards">
                                <ProjectCard
                                    title={project.title}
                                    link={project.link}
                                    logo={project.img}
                                    description={project.description}
                                    term={project.term}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="narrow">
                <img
                    className="notion-pic"
                    src={Diagonal2}
                    alt="Zoom codelab"
                />
                <h1 id="more-than-code">
                    A whole lot more than lines of code.
                </h1>
                <p id="more-than-code-description">
                    Throughout the project terms, we host numerous social and
                    professional events. CodeLab members have many opportunities
                    to make new friends and learn new things even beyond
                    computer science or design.
                </p>
                <div className="row more-than-code-pics">
                    <div className="col-md-6">
                        <div
                            className="image"
                            style={{ backgroundImage: `url(${Rectangle1})` }}
                            title="Associates in team meeting"
                        />
                    </div>
                    <div className="col-md-6">
                        <div
                            className="image"
                            style={{ backgroundImage: `url(${Rectangle2})` }}
                            title="Group photo at Memorial Union"
                        />
                    </div>
                    <div className="col-md-4">
                        <div
                            className="image square"
                            style={{ backgroundImage: `url(${Square1})` }}
                            title="Associates discussing at whiteboard"
                        />
                    </div>
                    <div className="col-md-4">
                        <div
                            className="image square"
                            style={{ backgroundImage: `url(${Square2})` }}
                            title="Board member selfie"
                        />
                    </div>
                    <div className="col-md-4 ">
                        <div
                            className="image square"
                            style={{ backgroundImage: `url(${Square3})` }}
                            title="Project manager giving explanation at whiteboard"
                        />
                    </div>
                </div>
                <h1 id="get-involved">Get Involved.</h1>
                <div className="row students-company">
                    <div className="col-md-6 learn-more-card">
                        <CalloutCard
                            colorScheme="dark"
                            title="✏️ Students"
                            description="UC Davis students, we would love for you to join us! We recruit new members twice a year, once in Fall Quarter and once in Winter Quarter."
                            buttonTitle="Learn More"
                            buttonLink="/involvement"
                        />
                    </div>
                    <div className="col-md-6">
                        <CalloutCard
                            colorScheme="dark"
                            title="🚀 Companies"
                            description="We are always looking for companies and organizations to work with. If you are interested, please reach out!"
                            buttonTitle="Learn More"
                            buttonLink="/work"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
