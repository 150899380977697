import "@css/PastMember.css";

import logo from "@images/About/linkedIn_logo.svg";

function PastMember(props) {
    return (
        <div id="past-member-wrapper">
            <div
                className="profile"
                style={{ backgroundImage: `url(${props.img})` }}
            />
            <p className="small-text past-member-name">
                {props.name}
                {props.linkedin ? (
                    <a href={props.linkedin}>
                        <img className="logo" src={logo} alt="LinkedIn logo" />
                    </a>
                ) : (
                    ""
                )}
            </p>

            <p className="small-text last-cohort">{props.last_cohort}</p>
        </div>
    );
}

export default PastMember;
