import "@css/Member.css";

import logo from '@images/About/linkedIn_logo.svg';

function Member(props) {
    // console.log(props)
    return (
        <div id="member-wrapper">
            <div className="profile" style={{backgroundImage: `url(${props.img})`}} />
            <p className="member-name">{props.name}</p>
            { props.linkedin && <a href={props.linkedin}><img className='logo' src={logo} alt=""/></a> }
            { props.role === 'executive' && <p className="member-title member-title-red">{props.title}</p>}
            { props.role === 'board' && <p className="member-title member-title-purple">{props.title}</p>}
            { props.role === 'associate' && <p className="member-title member-title-blue">{props.title}</p>}
        </div>
    );
}

export default Member;